* {
  box-sizing: border-box;
}

.css_vg6we9 label {
  display: block;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.css_1u763tv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  height: 2.933rem;
  border: 1px solid #eaecee;
  border-radius: 6px;
  background-color: #fff;
  cursor: text;
}

.css_1u763tv:hover {
  border-color: #c6cad0;
}

.css_1u763tv input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
}

.css_1u763tv input::placeholder {
  color: #c6cad0;
}

.css_10xzrfw {
  padding: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
}

.css_10xzrfw:disabled {
  -webkit-text-fill-color: #a1aab2;
  opacity: 1;
  color: #a1aab2;
  cursor: not-allowed;
}

.css_1gerhyj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  border: 0;
  border-radius: 6px;
  background: none;
  cursor: pointer;
  margin: -8px -8px -8px 0;
}

.css_1gerhyj:enabled:hover {
  background-color: #f2f4f6;
}

.css_1gerhyj:disabled {
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .css_vg6we9 {
    background: #f2f4f6;
  }
}

.css_1tnomb9 {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.3px;
  white-space: pre-line;
  word-break: break-all;
  position: absolute;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.css_1k9ek97 {
  background-color: #fff;
}

.css_8mcuh1 {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.3px;
  white-space: pre-line;
  word-break: break-all;
  display: none;
}

.css_l7llpu {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 0.000rem;
  margin: 0;
  padding: 0 32px;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@media (max-width: 1024px) {
  .css_l7llpu {
    padding: 0 32px;
  }
}

@media (max-width: 768px) {
  .css_l7llpu {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 16px;
    gap: 16px;
  }
}

.css_cvvyv5 {
  -webkit-flex: 0 1 56.000rem;
  -ms-flex: 0 1 56.000rem;
  flex: 0 1 56.000rem;
  margin-right: 16px;
  padding: 32px;
}

@media (max-width: 1024px) {
  .css_cvvyv5 {
    -webkit-flex: 0 1 55.467rem;
    -ms-flex: 0 1 55.467rem;
    flex: 0 1 55.467rem;
    padding: 32px 28px;
  }
}

@media (max-width: 768px) {
  .css_cvvyv5 {
    -webkit-flex: 1 1 fit-content;
    -ms-flex: 1 1 fit-content;
    flex: 1 1 fit-content;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.css_133pvyo {
  margin-bottom: 10px;
  width: 100%;
  height: 3.733rem;
}

@media (max-width: 768px) {
  .css_133pvyo {
    height: 2.267rem;
    margin-bottom: 8px;
  }
}

.css_133pvyo>input {
  width: 100%;
  height: 3.733rem;
  font-size: 40px;
  font-weight: 700;
  text-underline-offset: 0.533rem;
}

@media (max-width: 768px) {
  .css_133pvyo>input {
    font-size: 24px;
    height: 2.267rem;
    text-underline-offset: 0.333rem;
  }
}

.css_1hyfx7x {
  display: none;
}

.css_1qvqm7m {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  border: none;
  padding: 0;
  outline: none;
  -webkit-text-decoration: underline #21252a 1px;
  text-decoration: underline #21252a 1px;
  letter-spacing: -0.3px;
}

.css_1qvqm7m::placeholder {
  color: #a1aab2;
}

.css_m16sm3 {
  margin-bottom: 24px;
  width: 100%;
  height: 2.267rem;
  max-width: 100%;
}

@media (max-width: 768px) {
  .css_m16sm3 {
    height: 1.600rem;
    margin-bottom: 16px;
  }
}

.css_m16sm3>input {
  width: 100%;
  max-width: 100%;
  height: 2.267rem;
  font-size: 24px;
  font-weight: 500;
  text-underline-offset: 0.333rem;
}

@media (max-width: 768px) {
  .css_m16sm3>input {
    font-size: 18px;
    height: 1.733rem;
    text-underline-offset: 0.200rem;
  }
}

.css_1chys3l {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 0.000rem;
  gap: 16px;
  display: grid;
  -webkit-align-items: baseline;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  grid-template-rows: auto;
  row-gap: 2px;
  -webkit-column-gap: 16px;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "phoneNumber email" "addressMainAndBuildingName addressMainAndBuildingName" "coverLetter coverLetter";
}

@media (max-width: 768px) {
  .css_1chys3l {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0px;
  }
}

@media (max-width: 768px) {
  .css_1chys3l>*+* {
    margin-top: 0;
    margin-left: 0;
  }
}

.css_1chys3l>div:nth-of-type(1) {
  grid-area: phoneNumber;
}

.css_1chys3l>div:nth-of-type(2) {
  grid-area: email;
  width: 100%;
}

.css_1chys3l>div:nth-of-type(3) {
  grid-area: addressMainAndBuildingName;
}

.css_1chys3l>div:nth-of-type(4) {
  grid-area: coverLetter;
}

@media (max-width: 768px) {
  .css_1chys3l {
    row-gap: 2px;
    -webkit-column-gap: 8px;
    column-gap: 8px;
    grid-template-columns: 100%;
    grid-template-areas: "phoneNumber" "email" "addressMainAndBuildingName" "coverLetter";
  }
}

.css_19uskpi {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  width: 100%;
}

.css_14ly3ew {
  position: absolute;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.css_79elbk {
  position: relative;
}

.css_817y00 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
  color: #21252a;
  word-break: break-all;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.css_mdh6b {
  margin-left: 3px;
  color: #e50000;
}

.css_1qnlkji {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  height: 2.933rem;
  border: 1px solid #eaecee;
  border-radius: 6px;
  background-color: #fff;
}

.css_1qnlkji:hover {
  border-color: #c6cad0;
}

.css_1qnlkji>*+* {
  margin-left: 16px;
}

.css_yiolqq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
  color: #21252a;
}

.css_yiolqq>*+* {
  margin-top: 2px;
  margin-left: 2px;
}

.css_koyv6e {
  display: inline-block;
  min-width: 38px;
  color: inherit;
}

.css_12r39mb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.css_jpk05f {
  -moz-appearance: textfield;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
  color: #21252a;
  border: 0;
  width: 100%;
  background: none;
  outline: none;
  text-align: left;
}

.css_jpk05f:disabled {
  -webkit-text-fill-color: #a1aab2;
  opacity: 1;
  color: #a1aab2;
}

.css_jpk05f::placeholder {
  color: #c6cad0;
}

.css_olpytc {
  box-sizing: border-box;
  position: absolute;
  top: calc(100% + 8px);
  overflow-y: auto;
  margin: 0;
  padding: 16px;
  max-height: 22.000rem;
  background-color: #fff;
  border: 1px solid #eaecee;
  border-radius: 6px;
  list-style: none;
  z-index: 1;
  position: absolute;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@media (max-width: 768px) {
  .css_olpytc {
    max-height: 16.667rem;
  }
}

.css_he45e8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 500;
  color: #21252a;
  border-radius: 2px;
  cursor: default;
}

.css_he45e8>*+* {
  margin-top: 12px;
  margin-left: 12px;
}

.css_he45e8:hover {
  background-color: #f8f9fa;
}

.css_he45e8.ccc-item--selected {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: bold;
  color: #00CCAA;
  background-color: #f8f9fa;
}

.css_he45e8>dt {
  width: 39px;
}

.css_he45e8>dd {
  margin: 0;
}

.css_fiamws {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 4px;
  opacity: 0;
}

.css_ce81wa {
  position: relative;
  top: 1px;
  margin-left: 4px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: normal;
  color: #e50000;
}

.css_166vh9w {
  width: 100%;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  width: 100%;
}

.css_1u763tv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  height: 2.933rem;
  border: 1px solid #eaecee;
  border-radius: 6px;
  background-color: #fff;
  cursor: text;
}

.css_1u763tv:hover {
  border-color: #c6cad0;
}

.css_1u763tv input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
}

.css_1u763tv input::placeholder {
  color: #c6cad0;
}

.css_10xzrfw {
  padding: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
}

.css_10xzrfw:disabled {
  -webkit-text-fill-color: #a1aab2;
  opacity: 1;
  color: #a1aab2;
  cursor: not-allowed;
}

.css_11yoqnl {
  width: 100%;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  width: 100%;
}

.css_1gerhyj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  border: 0;
  border-radius: 6px;
  background: none;
  cursor: pointer;
  margin: -8px -8px -8px 0;
}

.css_1gerhyj:enabled:hover {
  background-color: #f2f4f6;
}

.css_1gerhyj:disabled {
  cursor: not-allowed;
}

.css_ry0pp1 {
  width: 100%;
  -webkit-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  width: 100%;
}

.css_1vvgunx {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #eaecee;
  border-radius: 6px;
  background-color: #fff;
  cursor: text;
  overflow: auto;
}

.css_1vvgunx:hover {
  border-color: #c6cad0;
}

.css_1f6rmap {
  margin: 0;
  padding: 0.800rem 16px;
  width: 100%;
  height: 7.200rem;
  border: none;
  outline: none;
  background: #fff;
  font-family: inherit;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
  resize: vertical;
}

.css_1f6rmap::placeholder {
  color: #c6cad0;
}

.css_1f6rmap::placeholder {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
}

.css_1f6rmap:disabled {
  -webkit-text-fill-color: #a1aab2;
  color: #a1aab2;
  background: #f8f9fa;
  opacity: 1;
  cursor: not-allowed;
}

.css_1d7hmzo {
  margin: 0;
  margin-top: 4px;
  padding: 0;
  list-style: none;
}

.css_ab5dye {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.css_4duij1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px 4px 0 0;
  display: none;
}

.css_nwu3e5 {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  color: #a1aab2;
}

.css_1nd0w80 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 1 18.133rem;
  -ms-flex: 0 1 18.133rem;
  flex: 0 1 18.133rem;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 40px 21px 15px;
}

@media (max-width: 1024px) {
  .css_1nd0w80 {
    padding: 40px 8px;
  }
}

@media (max-width: 768px) {
  .css_1nd0w80 {
    -webkit-flex: 0 0 9.333rem;
    -ms-flex: 0 0 9.333rem;
    flex: 0 0 9.333rem;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    margin-left: 0;
    padding: 0;
  }
}

.css_xi606m {
  text-align: center;
}

.css_1v0y0ql {
  position: relative;
  display: block;
  width: 256px;
  border-radius: 6px;
  width: 256px;
  background-color: #fff;
  width: 15.333rem;
  height: 15.333rem;
  background-color: transparent;
}

@media (max-width: 768px) {
  .css_1v0y0ql {
    width: 9.333rem;
    height: 9.333rem;
  }
}

.css_1v0y0ql>div>div {
  border-radius: 20px 20px 20px 20px;
}

@media (max-width: 768px) {
  .css_1v0y0ql button {
    display: none;
  }
}

@media (max-width: 768px) {
  .css_1v0y0ql p {
    display: none;
  }
}

.css_vx1dyz {
  width: 100%;
  padding-top: 56.25%;
}

.css_wbgzev {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #eaecee;
  border-radius: 6px;
}

.css_wbgzev:hover {
  border-color: #c6cad0;
}

.css_wbgzev:hover>.image-uploader-cover {
  opacity: 0.14;
}

.css_wbgzev:hover button {
  opacity: 1;
}

.css_14dxa7q {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  z-index: unset;
}

.css_1ngnjh4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #000;
  z-index: 2;
}

.css_hdg8y2 {
  margin: 6px 0 0 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: normal;
  color: #a1aab2;
}

.css_1gx0rvp {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #f2f4f6;
  color: #535961;
  padding: 7px 12px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: -0.3px;
  font-weight: bold;
  z-index: 3;
  margin-top: 16px;
}

.css_1gx0rvp:disabled {
  cursor: not-allowed;
}

.css_1gx0rvp:hover,
.css_1gx0rvp:active {
  background-color: #dfe2e6;
}

.css_1gx0rvp:disabled {
  background-color: #f2f4f6;
  color: #c6cad0;
}

.css_hspij9 {
  margin-top: 8px;
  font-size: 0.800rem;
  line-height: 1.200rem;
  color: #878e98;
}

@media (max-width: 768px) {
  .css_hspij9 {
    margin-top: 16px;
  }
}

.css_1jmiz42 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: #f2f4f6;
}

.css_fyikhi {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.3px;
  white-space: pre-line;
  word-break: break-all;
}

.css_5m4ka1 {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 17.067rem;
  row-gap: 0px;
  -webkit-column-gap: 2.133rem;
  column-gap: 2.133rem;
  margin: 32px;
  width: 100%;
  max-width: 75.733rem;
}

@media (max-width: 1024px) {
  .css_5m4ka1 {
    max-width: 73.867rem;
  }
}

@media (max-width: 768px) {
  .css_5m4ka1 {
    grid-template-columns: 1fr 0;
    margin: 0;
    padding: 16px 0;
    gap: 0;
    row-gap: 32px;
  }
}

.css_1qz0a5d {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  row-gap: 0px;
  -webkit-column-gap: 0px;
  column-gap: 0px;
  row-gap: 32px;
}

@media (max-width: 768px) {
  .css_1qz0a5d {
    row-gap: 16px;
  }
}

.css_c3ve7p {
  border-radius: 16px;
  padding: 32px;
  background-color: #fff;
  padding-bottom: 10px;
}

@media (max-width: 1024px) {
  .css_c3ve7p {
    padding: 28px;
  }
}

@media (max-width: 768px) {
  .css_c3ve7p {
    padding: 16px;
    border-radius: 0;
  }
}

@media (max-width: 1024px) {
  .css_c3ve7p {
    padding-bottom: 6px;
  }
}

@media (max-width: 768px) {
  .css_c3ve7p {
    padding-bottom: 0;
  }
}

.css_nxdy76 {
  border-bottom: 3px solid #21252a;
  margin-bottom: 32px;
  padding-bottom: 7px;
}

@media (max-width: 1024px) {
  .css_nxdy76 {
    margin-bottom: 28px;
  }
}

@media (max-width: 768px) {
  .css_nxdy76 {
    margin-bottom: 16px;
  }
}

.css_swd362 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.000rem;
  height: 2.933rem;
}

@media (max-width: 768px) {
  .css_swd362 {
    height: 2.533rem;
  }
}

.css_1siu9uq {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-items: end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  gap: 0.000rem;
  height: 2.267rem;
}

@media (max-width: 768px) {
  .css_1siu9uq {
    height: 1.867rem;
  }
}

.css_1vin2lb {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.3px;
  white-space: pre-line;
  word-break: break-all;
  margin: 0;
}

@media (max-width: 768px) {
  .css_1vin2lb {
    font-size: 20px;
    line-height: 1.867rem;
  }
}

.css_gak923 {
  height: 1.867rem;
  margin-left: 4px;
  color: #e50000;
  font-weight: 500;
  font-size: 0.933rem;
  line-height: 1.333rem;
}

@media (max-width: 768px) {
  .css_gak923 {
    font-size: 0.867rem;
    line-height: 1.267rem;
  }
}

.css_zdpt2t {
  margin-left: auto;
}

.css_1dw3lh5 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 0.000rem;
  gap: 16px;
}

@media (max-width: 768px) {
  .css_1dw3lh5 {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0px;
  }
}

.css_180u3m1 {
  width: 100%;
}

.css_13o7eu2 {
  display: block;
}

.css_18ia305 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  height: 2.933rem;
  border: 1px solid #eaecee;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}

.css_18ia305:hover {
  border-color: #c6cad0;
}

.css_1i1o4y5 {
  overflow: hidden;
  margin-right: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
  color: #21252a;
}

.css_116b9m9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.css_1x0fcs1 {
  box-sizing: border-box;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 8px);
  margin: 0;
  padding: 16px;
  width: 100%;
  max-height: 22.000rem;
  list-style: none;
  background-color: #fff;
  border: 1px solid #eaecee;
  border-radius: 6px;
  z-index: 1;
  position: absolute;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@media (max-width: 768px) {
  .css_1x0fcs1 {
    max-height: 16.667rem;
  }
}

.css_ivzpf6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  padding: 10px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 500;
  color: #21252a;
  cursor: pointer;
}

.css_ivzpf6:hover {
  border-radius: 2px;
  background-color: #f8f9fa;
}

.css_ivzpf6.option--selected {
  background-color: #f2f4f6;
  font-weight: bold;
  color: #21252a;
  border-radius: 2px;
}

.css_ivzpf6>span {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
}

.css_1p5dvd8 {
  border-radius: 16px;
  padding: 32px;
  background-color: #fff;
}

@media (max-width: 1024px) {
  .css_1p5dvd8 {
    padding: 28px;
  }
}

@media (max-width: 768px) {
  .css_1p5dvd8 {
    padding: 16px;
    border-radius: 0;
  }
}

.css_1c9tpv {
  position: relative;
  position: relative;
  display: block;
}

.css_2uv5lm {
  width: 100%;
}

.css_ihqoun {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
  height: 2.933rem;
  border: 1px solid #eaecee;
  border-radius: 6px;
  background-color: #fff;
  cursor: text;
  border: 1px solid #eaecee;
}

.css_ihqoun:hover {
  border-color: #c6cad0;
}

.css_ihqoun input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
}

.css_ihqoun input::placeholder {
  color: #c6cad0;
}

.css_ihqoun:hover {
  border: 1px solid #c6cad0;
}

.css_gqpgy4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  border: 0;
  border-radius: 6px;
  background: none;
  cursor: pointer;
  margin: -8px 8px -8px -8px;
}

.css_gqpgy4:enabled:hover {
  background-color: #f2f4f6;
}

.css_gqpgy4:disabled {
  cursor: not-allowed;
}

.css_1al1mym {
  padding: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
}

.css_1al1mym:disabled {
  -webkit-text-fill-color: #a1aab2;
  opacity: 1;
  color: #a1aab2;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .css_1al1mym {
    scroll-margin-top: 89px;
  }
}

.css_m9mwd1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 4px;
  display: none;
}

.css_18hl5m5 {
  margin: 0 0 2px 8px;
  color: #878e98;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.333rem;
}

@media (max-width: 768px) {
  .css_18hl5m5 {
    font-size: 13px;
    line-height: 1.267rem;
  }
}

.css_1t87mh9 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #fff;
  color: #535961;
  padding: 8px 12px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: bold;
  width: 2.933rem;
  height: 2.933rem;
  border: none;
}

.css_1t87mh9:disabled {
  cursor: not-allowed;
}

.css_1t87mh9:hover,
.css_1t87mh9:active {
  background-color: #f2f4f6;
}

.css_1t87mh9:disabled {
  background-color: #fff;
  color: #c6cad0;
}

.css_1t87mh9 .rallit-icon {
  width: 0.933rem;
  height: 0.933rem;
  margin: 0;
}

.css_1t87mh9 .rallit-icon>svg>path {
  fill: #535961;
}

@media (max-width: 768px) {
  .css_1t87mh9 {
    width: 2.533rem;
    height: 2.533rem;
  }
}

.css_822ryp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.css_aj40vv {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.css_fstzjo {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  font-weight: bold;
  white-space: pre-line;
  word-break: break-all;
  margin: 0;
}

.css_1o00n93 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #f2f4f6;
  color: #535961;
  padding: 12px 14px 12px 12px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: bold;
  margin-top: 8px;
  width: 100%;
  margin-top: 8px;
}

.css_1o00n93:disabled {
  cursor: not-allowed;
}

.css_1o00n93:hover,
.css_1o00n93:active {
  background-color: #dfe2e6;
}

.css_1o00n93:disabled {
  background-color: #f2f4f6;
  color: #c6cad0;
}

.css_1o00n93 .rallit-icon {
  width: 1.067rem;
  height: 1.067rem;
  margin: 0px 4px 0px 0px;
}

.css_1o00n93 .rallit-icon>svg>path {
  fill: #535961;
}

@media (max-width: 768px) {
  .css_1o00n93 {
    margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .css_1o00n93 {
    margin-top: 8px;
  }
}

@media (max-width: 768px) {
  .css_1o00n93 {
    margin-top: 8px;
  }
}

.css_wdosil {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  -webkit-box-flex-flow: column-reverse;
  -webkit-flex-flow: column-reverse;
  -ms-flex-flow: column-reverse;
  flex-flow: column-reverse;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  row-gap: 8px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 32px;
  padding: 0;
  list-style: none;
}

@media (max-width: 1024px) {
  .css_wdosil {
    margin-bottom: 28px;
  }
}

@media (max-width: 768px) {
  .css_wdosil {
    margin-bottom: 22px;
  }
}

.css_u7t9ih {
  padding-left: 20px;
  color: #878e98;
  margin-top: 32px;
}

.css_u7t9ih p {
  margin: 0;
}

@media (max-width: 1024px) {
  .css_u7t9ih {
    margin-top: 28px;
  }
}

@media (max-width: 768px) {
  .css_u7t9ih {
    margin-top: 22px;
  }
}

.css_oz575p {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  word-break: break-all;
}

.css_2g8nzb {
  position: -webkit-sticky;
  position: sticky;
  display: grid;
  row-gap: 8px;
  top: 80px;
}

@media (max-width: 768px) {
  .css_2g8nzb {
    display: none;
  }
}

.css_t0671v {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #ea580c;
  color: #fff;
  padding: 12px 14px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: bold;
}

.css_t0671v:disabled {
  cursor: not-allowed;
}

.css_t0671v:hover,
.css_t0671v:active {
  opacity: 0.9;
}

.css_t0671v:disabled {
  background-color: #CBF4ED;
}

.css_1ctunha {
  margin: 0px;
  padding: 0.8rem 16px;
  width: 100%;
  height: 25rem;
  border: none;
  outline: none;
  background: rgb(255, 255, 255);
  font-family: inherit;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
  resize: vertical;
}

.css_1ctunha::placeholder {
  color: rgb(198, 202, 208);
}

.css_1ctunha::placeholder {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
}

.css_1ctunha:disabled {
  -webkit-text-fill-color: rgb(161, 170, 178);
  color: rgb(161, 170, 178);
  background: rgb(248, 249, 250);
  opacity: 1;
  cursor: not-allowed;
}

.css_2rxz80 {
  position: sticky;
  margin-top: 77px;
  width: 100%;
  bottom: 0;
  z-index: 1;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid rgb(234, 236, 238);
}

@media (min-width: 768px) {
  .css_2rxz80 {
    display: none;
  }
}

.css_1egouti {
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 0.533rem;
  padding: 0px 16px;
  margin: 0px auto;
  height: 4.867rem;
  max-width: 75.733rem;
}

.css_1vmctu7 {
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: #ea580c;
  color: rgb(255, 255, 255);
  padding: 12px 14px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: bold;
  width: 100%;
}

.css_1vmctu7:disabled {
  cursor: not-allowed;
}

.css_1vmctu7:hover,
.css_1vmctu7:active {
  opacity: 0.9;
}

.css_1vmctu7:disabled {
  background-color: rgb(203, 244, 237);
}


@font-face {
  font-family: Pretendard;
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"), url(https://www.rallit.com/fonts/Pretendard/Pretendard-Bold.subset.woff2) format("woff2"), url(https://www.rallit.com/fonts/Pretendard/Pretendard-Bold.subset.woff) format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard Medium"), url(https://www.rallit.com/fonts/Pretendard/Pretendard-Medium.subset.woff2) format("woff2"), url(https://www.rallit.com/fonts/Pretendard/Pretendard-Medium.subset.woff) format("woff");
}

@font-face {
  font-family: Pretendard;
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"), url(https://www.rallit.com/fonts/Pretendard/Pretendard-Regular.subset.woff2) format("woff2"), url(https://www.rallit.com/fonts/Pretendard/Pretendard-Regular.subset.woff) format("woff");
}

/* header sticky 전역 적용문제 해결 */
.css_vg6we9 header {
  position: unset;
}