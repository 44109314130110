.mainDiv {
  text-align: left;
  /* white-space: pre-line; */
}

* {
  box-sizing: inherit;
}

code {
  font-family: "Fira Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
#root {
  height: 100%;
}
.iZAesw {
  position: fixed;
  top: 0px;
  background: var(--bg-element1);
  width: 100%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
}
.cMpExe {
  padding-bottom: 4rem;
}
* {
  box-sizing: inherit;
}
code {
  font-family: "Fira Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
#root {
  height: 100%;
}
* {
  box-sizing: inherit;
}
code {
  font-family: "Fira Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
#root {
  height: 100%;
}
.ckXmXH {
  width: 768px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .ckXmXH {
    width: 100%;
  }
}
.hvSIqH {
  margin-top: 5.5rem;
}
@media (max-width: 1024px) {
  .hvSIqH .head-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.hvSIqH h1 {
  font-size: 2.5rem;
  line-height: 1.5;
  letter-spacing: -0.004em;
  margin-top: 0px;
  font-weight: 800;
  color: var(--text1);
  margin-bottom: 2rem;
  word-break: keep-all;
  transition: color 0.125s ease-in 0s;
}
@media (max-width: 1024px) {
  .hvSIqH {
    margin-top: 2rem;
  }
  .hvSIqH h1 {
    font-size: 2rem;
  }
}
.bNEPsq {
  -webkit-box-align: center;
  align-items: center;
  font-size: 1rem;
  color: var(--text2);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .bNEPsq .information {
    font-size: 0.875rem;
  }
}
@media (max-width: 768px) {
  .bNEPsq {
    margin-bottom: 0.75rem;
  }
}
.fnxPLK {
  margin-top: 16rem;
  margin-bottom: 6rem;
}
@media (max-width: 1024px) {
  .fnxPLK {
    margin-top: 8rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 768px) {
  .fnxPLK {
    margin-top: 2rem;
  }
}
.fZOLYn {
  font-size: 1.125rem;
  color: var(--text1);
  transition: color 0.125s ease-in 0s;
  line-height: 1.7;
  letter-spacing: -0.004em;
  word-break: keep-all;
  overflow-wrap: break-word;
}
.fZOLYn code {
  font-family: "Fira Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
.fZOLYn hr {
  border: none;
  height: 1px;
  width: 100%;
  background: var(--border3);
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.fZOLYn h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
}
.fZOLYn h3 {
  font-size: 1.5rem;
  font-weight: bold;
}
.fZOLYn h4 {
  font-size: 1.125rem;
}
.fZOLYn h3,
.fZOLYn h4 {
  line-height: 1.5;
  margin-bottom: 1rem;
}
.fZOLYn p + h4 {
  margin-top: 2.5rem;
}
@media (max-width: 768px) {
  .fZOLYn {
    font-size: 1rem;
  }
  .fZOLYn h3 {
    font-size: 1.25rem;
  }
  .fZOLYn h4 {
    font-size: 1rem;
  }
  .fZOLYn h3,
  .fZOLYn h4 {
    margin-bottom: 0.75rem;
  }
  .fZOLYn p + h4 {
    margin-top: 2rem;
  }
}
.kLqDvr.atom-one pre {
  background: var(--prism-bg);
}
.kLqDvr.atom-one code[class*="language-"] {
  color: var(--prism-default-text);
  background: none;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  overflow-wrap: normal;
  tab-size: 4;
  hyphens: none;
}
.kLqDvr.atom-one code[class*="language-"]::selection {
  text-shadow: none;
  background: var(--prism-selection-bg);
}
@media print {
  .kLqDvr.atom-one code[class*="language-"] {
    text-shadow: none;
  }
}
.kLqDvr pre {
  font-family: "Fira Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  font-size: 0.875rem;
  padding: 1rem;
  border-radius: 4px;
  line-height: 1.5;
  overflow-x: auto;
  letter-spacing: 0px;
}
@media (max-width: 768px) {
  .kLqDvr pre {
    font-size: 0.75rem;
    padding: 0.75rem;
  }
}
.kuRkHh {
  max-height: 100vh;
  max-width: 100%;
  width: auto;
  margin: 2rem auto 0px;
  height: auto;
  object-fit: contain;
  display: block;
}
@media (max-width: 768px) {
  .kuRkHh {
    margin-top: 1.5rem;
  }
}
.esFXbZ {
  width: 768px;
  margin: 4rem auto 0px;
}
@media (max-width: 1024px) {
  .esFXbZ {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 768px) {
  .esFXbZ {
    width: 100%;
  }
}
.ekVKiN {
  margin-top: 3rem;
  display: flex;
}
@media (max-width: 768px) {
  .ekVKiN {
    flex-direction: column-reverse;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.eQJycs {
  min-width: 0px;
  flex: 1 1 0%;
}
.sc-dkqQuH + .sc-dkqQuH {
  margin-left: 3rem;
}
@media (max-width: 768px) {
  .eQJycs {
    flex: initial;
    width: 100%;
  }
  .sc-dkqQuH + .sc-dkqQuH {
    margin-left: 0px;
    margin-bottom: 1.5rem;
  }
}
.iRxqno {
  margin-bottom: -4rem;
}
/*! CSS Used fontfaces */
@font-face {
  font-display: swap;
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 400;
  src: local("Fira Mono Regular"), local("FiraMono-Regular"),
    url(https://fonts.gstatic.com/s/firamono/v7/N0bX2SlFPv1weGeLZDtgK_7Ss9XZYalI.woff2)
      format("woff2");
  unicode-range: U+0460-052f, U+1c80-1c88, U+20b4, U+2de0-2dff, U+a640-a69f,
    U+fe2e-fe2f;
}
@font-face {
  font-display: swap;
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 400;
  src: local("Fira Mono Regular"), local("FiraMono-Regular"),
    url(https://fonts.gstatic.com/s/firamono/v7/N0bX2SlFPv1weGeLZDtgIv7Ss9XZYalI.woff2)
      format("woff2");
  unicode-range: U+0400-045f, U+0490-0491, U+04b0-04b1, U+2116;
}
@font-face {
  font-display: swap;
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 400;
  src: local("Fira Mono Regular"), local("FiraMono-Regular"),
    url(https://fonts.gstatic.com/s/firamono/v7/N0bX2SlFPv1weGeLZDtgKv7Ss9XZYalI.woff2)
      format("woff2");
  unicode-range: U+1f??;
}
@font-face {
  font-display: swap;
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 400;
  src: local("Fira Mono Regular"), local("FiraMono-Regular"),
    url(https://fonts.gstatic.com/s/firamono/v7/N0bX2SlFPv1weGeLZDtgJf7Ss9XZYalI.woff2)
      format("woff2");
  unicode-range: U+0370-03ff;
}
@font-face {
  font-display: swap;
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 400;
  src: local("Fira Mono Regular"), local("FiraMono-Regular"),
    url(https://fonts.gstatic.com/s/firamono/v7/N0bX2SlFPv1weGeLZDtgKP7Ss9XZYalI.woff2)
      format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1e??, U+2020, U+20a0-20ab, U+20ad-20cf,
    U+2113, U+2c60-2c7f, U+a720-a7ff;
}
@font-face {
  font-display: swap;
  font-family: Fira Mono;
  font-style: normal;
  font-weight: 400;
  src: local("Fira Mono Regular"), local("FiraMono-Regular"),
    url(https://fonts.gstatic.com/s/firamono/v7/N0bX2SlFPv1weGeLZDtgJv7Ss9XZYQ.woff2)
      format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da,
    U+02dc, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+feff, U+fffd;
}

/* 이미지 첨부 */
.kLqDvr p img {
  display: block;
  margin: 3rem auto;
  max-width: 100%;
}
.kLqDvr blockquote {
  margin: 2rem 0px;
  border-left: 4px solid #ea580c;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #f8f9fa;
  padding: 1rem 1rem 1rem 2rem;
  color: #212529;
}
.kLqDvr blockquote :first-child {
  margin-top: 0px;
}
.kLqDvr blockquote :last-child {
  margin-bottom: 0px;
}
.kLqDvr img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/* a태그 줄바뀜 방지 */
.mainDiv a {
  display: inline;
}

/* h2안의 ul */
h2 ul {
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

/* 모바일 h2 태그 */
@media (max-width: 768px) {
  .sc-fXEqDS h2 {
    font-size: 1.5rem;
  }
}

/* 연락 */
.strong {
  font-style: italic;
  font-weight: bold;
}

/* blockquote img 중앙정렬 */
blockquote img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
