.main {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  text-align: left;
}

/* figure img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: transparent;
} */

.css_1xw6rpm {
  max-width: 1084px;
  width: 100%;
  margin: 20px auto;
}

.css_1ixgpnt {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0rem;
  margin-bottom: 20px;
}

.css_1elspdf {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.3px;
  white-space: pre-line;
  word-break: break-all;
  margin: 0;
}

.css_fl7us7 {
  margin-left: auto;
}

.css_p1vanc {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0rem;
}

.css_byjqmz {
  cursor: pointer;
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  word-break: break-all;
  margin: 0;
  color: #535961;
}

.css_1gvano {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

/* ul 박스 */
.css_1nwykk {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 10px 1.333rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

.css_1nwykk li {
  cursor: pointer;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.css_1pr9edg {
  position: relative;
  height: 100%;
  color: #21252a;
}

.css_1pr9edg a {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.css_1gd0osy {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding-top: 55.46875%;
  border-radius: 6px;
  border: solid 1px #f2f4f6;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.css_1gd0osy img {
  opacity: 1;
  background-color: #fff;
  object-fit: cover;
  object-position: center center;
}

/* 박스 밑 상세 설명 */
.wrapBox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 10px;
  height: 100%;
}

.wrapBox .mainTitle {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: normal;
}

.css_fk1zjv {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: pre-line;
  -webkit-line-clamp: 1;
}

.wrapBox .userName {
  margin: 2px 0 6px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  font-weight: 500;
}

.css_1to8sx0 {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  white-space: pre-line;
  word-break: break-all;
}

.css_1kivyvq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 4px;
  padding: 0;
  height: 1.267rem;
  overflow: hidden;
  list-style: none;
}

.css_1cyvqo4 {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  word-break: break-all;
  color: #878e98;
}

.css_1uz98zw + * {
  margin-left: 13px;
}

.css_1uz98zw {
  position: relative;
}

.css_1uz98zw + *:before {
  position: absolute;
  bottom: 4px;
  left: -6px;
  display: block;
  content: "";
  width: 1px;
  height: 10px;
  background: #878e98;
}

/* 최하단 설명 */
.css_1rtzn1u {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0rem;
}

.css_1rtzn1u span > * {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: 500;
  color: #878e98;
}

.css_oz575p {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  word-break: break-all;
}

.css_106dojg {
  background-color: #dfe2e6;
  width: 1px;
  height: 0.9em;
  margin: 0 6px;
}

.css_1rtzn1u span > * {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: 500;
  color: #878e98;
}

.css_oz575p {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  word-break: break-all;
}

.css_16nb092 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 0rem;
  margin-top: 10px;
  height: 24px;
  min-height: 24px;
}

.css_1bdht9e {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.info {
  margin: 0 auto;
  width: 100%;
  max-width: 1084px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0 32px;
}

/* 미디어쿼리 */
@media (max-width: 768px) {
  /* 박스 간 간격 */
  .css_1nwykk {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px 0.667rem;
    /* 위아래, 양옆 */
  }

  .css_1elspdf {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", sans-serif;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    font-weight: bold;
  }

  .wrapBox .mainTitle {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    font-weight: normal;
  }

  .css_1elspdf {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", sans-serif;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    font-weight: bold;
  }

  .css_1rtzn1u span > * {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    font-weight: normal;
  }

  .css_1rtzn1u span > * {
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    font-weight: normal;
  }

  .info {
    padding: 0 20px;
  }
}

@media (min-width: 1130px) {
  .info {
    padding: 0;
  }
}
