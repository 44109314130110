@charset "utf-8";

.swiper {
  width: 100%;
  max-width: 1084px;
  /* height: 300px; */
}

.css-14c50mj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  /* height: 300px; */
  margin-bottom: calc(84px);
  padding: 0 32px;
  -webkit-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
}

.css-1yposw6 {
  position: relative;
  max-width: 1084px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transform: translateY(36px);
  -moz-transform: translateY(36px);
  -ms-transform: translateY(36px);
  transform: translateY(36px);
  border-radius: 6px;
  box-shadow: 0px 7px 30px -20px rgb(0 0 0 / 24%),
    0px 32px 54px -40px rgb(0 0 0 / 20%);
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-pointer-events {
  touch-action: pan-y;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* 이미지 커서 */
.css-14c50mj a {
  cursor: pointer;
  position: relative;
  margin: 0;
  width: 100%;
}

.css-14c50mj img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 하단부 버튼 */
.css-y001ci {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-align-items: start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 0rem;
  position: absolute;
  right: 1.067rem;
  bottom: 1.067rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 7.733rem;
  height: 1.867rem;
  z-index: 2;
}

.css-b4963y {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
  color: #fff;
  border-radius: 100px;
  width: 3.067rem;
  height: 1.867rem;
  border: solid 1px #fff;
  background-color: rgba(0, 0, 0, 0.72);
}

.css-b4963y p {
  margin: 0;
}

.css-oz575p {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  word-break: break-all;
}

.css-1ccx5y7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  padding: 0;
  border-radius: 100px;
  width: 1.867rem rem;
  height: 1.867rem;
  border: solid 1px #fff;
  background-color: rgba(0, 0, 0, 0.72);
}

.css-1ccx5y7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 2px;
  padding: 0;
  border-radius: 100px;
  width: 1.867rem;
  height: 1.867rem;
  border: solid 1px #fff;
}

.css-14c50mj button {
  background-color: rgba(0, 0, 0, 0.72);
}

.css-1ccx5y7:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .css-y001ci > div {
    margin: 0;
  }

  .css-y001ci {
    bottom: 12px;
    right: 12px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .css-14c50mj {
    height: auto;
    padding: 0;
    margin-bottom: 20px;
  }

  .css-1yposw6 {
    border-radius: unset;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
    box-shadow: none;
  }

  .css-y001ci button {
    display: none;
  }

  /* .css-1eg6wcz {
    height: auto;
    padding-top: 55.5%;
  } */

  /* 모바일 슬라이드 높이 */
  .swiper {
    /* height: 200px; */
  }
}

/* 버튼 hover 설정 */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 0.7;
}

/* [ 모바일 특화 배너 설정 ] */
@media (min-width: 1px) {
  .css-14c50mj a {
    height: 110px;
  }
}

@media (min-width: 320px) {
  .css-14c50mj a {
    height: 130px;
  }
}

@media (min-width: 425px) {
  .css-14c50mj a {
    height: 150px;
  }
}

@media (min-width: 500px) {
  .css-14c50mj a {
    height: 200px;
  }
}

@media (min-width: 768px) {
  .css-14c50mj a {
    height: 250px;
  }
}

@media (min-width: 1024px) {
  .css-14c50mj a {
    height: 300px;
  }
}
