@charset "utf-8";

* {
  box-sizing: border-box;
}

a {
  display: block;
}

.main {
  margin-top: 0;
  background-color: #fff;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

header {
  box-shadow: none;
}

.main_inner {
  display: flex;
  margin: 0px auto;
  max-width: 76.533rem;
  padding: 0px 32px;
  cursor: default;
}

/* nav */
.css_uebuml {
  display: none;
}

.css_1qtribh {
  position: inherit;
  top: 0px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 100;
}

/* 모달_2 */
.css_ntzr4d {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.6;
}

.css_139kjpe {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px 30px;
  width: 100%;
  border-bottom: 1px solid rgb(242, 244, 246);
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 700;
  color: rgb(33, 37, 42);
  cursor: pointer;
}

.css_11yz4m4 {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.css_1vgm1p2 {
  position: relative;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid rgb(234, 236, 238);
  background-color: rgb(255, 255, 255);
  color: rgb(33, 37, 42);
  padding: 0px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: -0.3px;
  font-weight: bold;
  height: 2.133rem;
  width: 2.133rem;
}

.css_1vgm1p2 .rallit-icon {
  width: 0.8rem;
  height: 0.8rem rem;
  margin: 0px;
}

.css_12r39mb {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
}

.css_11yz4m4 .dropdownList__container {
  display: block;
  width: 100%;
}

/* 모달_1 */
.css_gqe409 {
  position: absolute;
  top: 0px;
  width: 100%;
}

.css_gqe409 ul {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  width: 100%;
  margin: 0px;
  padding: 16px 20px;
}

.css_733rfy {
  padding: 0px;
  margin: 0px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  overflow: scroll;
}

.css_733rfy li {
  border-radius: unset;
  border-top: unset;
}

.css_83cc5o {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
}

.css_83cc5o:hover {
  border-radius: 5px;
  background-color: rgba(220, 220, 220, 0.25);
  color: #ea580c;
}

.css_umd2x0 {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
  background-color: rgb(242, 244, 246);
}

.css_yrphod {
  background-color: rgb(242, 244, 246);
  width: 100%;
  height: 1px;
  margin: 10px 0px;
}

.css_jrt6fi {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
}

.css_47e4vb {
  display: grid;
  margin-right: 4px;
  row-gap: 0px;
  grid-template-areas:
    "title"
    "caption";
}

.css_83cc5o .dropdownList__title {
  color: rgb(33, 37, 42);
  font-weight: 500;
}

.css_83cc5o p {
  margin: 0px;
}

.css_1182me {
  margin: 0px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: normal;
  text-align: start;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: pre-line;
  -webkit-line-clamp: 1;
  grid-area: title / title / title / title;
}

.css_vzncwq {
  margin: 0px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: normal;
  white-space: pre-line;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  grid-area: caption / caption / caption / caption;
  color: rgb(161, 170, 178);
}

/* aside */
.css_1xgvj9r {
  display: flex;
  position: relative;
  height: calc(100vh - 98px);
}

.css_1b7sljm {
  all: unset;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 10.2rem;
  padding: 24px 0px 12px;
}

.css_4cw3x0 {
  background-color: rgb(242, 244, 246);
  width: 1px;
  height: auto;
  margin: 0px 0px 0px 10px;
}

.css_e0v95d {
  all: unset;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 2.667rem;
}

.css_e0v95d:hover {
  background-color: rgba(220, 220, 220, 0.25);
}

.css_jvnoiv {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 12px;
  width: 100%;
  height: 100%;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 500;
  color: rgb(83, 89, 97);
  cursor: pointer;
  border-radius: 6px;
}

.css_jvnoiv:hover {
  color: #ea580c;
}


/* section */
.css_8jj0t0 {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc((100vh - 6.533rem) - 1px);
  padding: 32px 0px 0px 64px;
}

.css_1w7riy2 {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: -0.3px;
  white-space: pre-line;
  word-break: break-all;
  margin: 0px;
}

.css_1rqfylh {
  width: 100%;
  flex-direction: row;
  -webkit-box-pack: end;
  justify-content: flex-start;
  -webkit-box-align: start;
  align-items: start;
  gap: 0rem;
  display: flex;
  margin-top: 10px;
  color: rgb(135, 142, 152);
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  font-weight: normal;
}

.css_1k9gbrn {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 8px;
  color: rgb(0, 132, 244);
  text-decoration: underline;
  text-underline-position: under;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  font-weight: 500;
}

.css_15uwmeb {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
}


@media (max-width: 768px) {

  .main {
    margin-top: 0;
  }

  .main_inner {
    flex-direction: column;
    padding: 0px;
  }

  .css_uebuml {
    position: sticky;
    top: 0px;
    display: block;
    z-index: 999;
    padding: 0;
  }

  /* aside */
  .css_1xgvj9r {
    display: none;
  }

  /* section */
  .css_8jj0t0 {
    min-height: calc(100vh - 49px);
    margin-top: 8px;
    padding: 20px 20px 68px;
  }

  /* 메뉴 */
  .css_1w7riy2 {
    display: none;
  }
}

@media (max-width: 1084px) {
  .css_8jj0t0 {
    padding-left: 32px;
  }

  /* section */
  .css_8jj0t0 {
    padding-left: 32px;
  }
}