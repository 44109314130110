@charset "utf-8";

/* [ footer ] */

footer {
  display: block;
  text-align: left;
}

.footer_top {
  background-color: #f2f4f6;
  width: 100%;
  height: 1px;
  margin: 0;
}

.css_8t0ewa {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px;
}

.css_192tn2r {
  display: flex;
  align-items: center;
}

.css_b3uwyt {
  margin: 18px 0 0 0;
  color: #878e98;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: -0.3px;
  font-weight: 500;
}

.css_szycpx {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0rem;
  flex-wrap: wrap;
}

.css_sx764e {
  display: flex;
}

.css_sx764e>div {
  width: auto;
}

.css_sx764e a {
  color: #535961;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-weight: 600;
  margin-right: 32px;
}

.css_120gsus {
  margin-right: 32px;
}

/* media Query */

/* max-width */

@media (max-width: 767px) {
  .logo {
    margin-top: 16px;
  }

  .css_8t0ewa {
    padding: 32px 20px;
  }

  .css_192tn2r {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .css_b3uwyt {
    margin: 0;
  }

  .css_sx764e {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 12px 0 16px;
  }

  .css_sx764e>div {
    margin-top: 10px;
  }

  .css_sx764e a {
    margin-right: 18px;
  }

}

@media (max-width: 357px) {
  .css_szycpx a {
    margin-bottom: 10px;
  }
}