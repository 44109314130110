@charset "utf-8";

/* 모바일 가로 확장 */
.second_nav>ul {
  overflow-x: auto;
}

.second_nav>ul li {
  flex-shrink: 0;
  min-height: 56px;
  display: inline-flex;
  align-items: center;
}

/* [ header ] */

.logo {
  width: 91px;
  height: 23px;
  color: rgb(17, 24, 39);
  color: rgb(234, 88, 12);
}

.logo img {
  width: 91px;
  height: 23px;
}

.header {
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 6px;
  font-size: 15px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, sans-serif;
  color: #21252a;
  box-sizing: border-box;
  z-index: 40;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  margin-top: 0px;
}

nav {
  font-size: 15px;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, sans-serif;
  color: #21252a;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 32px;
  width: 100%;
  max-width: 1200px;
}

.second_header {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.second_nav {
  background-color: #fff;
}

.left_GNB,
.right_GNB {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, auto);
  width: fit-content;
  height: 100%;
}

.left_GNB {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0rem;
}

.right_GNB {
  grid-template-columns: repeat(5, auto);
  gap: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0rem;
}


/* right-GNB */

.css_1xxao48 {
  /* width: 100%; */
  display: flex;
}

.css_1xxao48 .header__search-wrapper {
  /* margin-right: 24px; */
  padding: 0 0 0 12px;
  width: 11rem;
  height: 2.533rem;
}

.css_1u763tv {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 2.933rem;
  border: 1px solid #eaecee;
  border-radius: 6px;
  background-color: #fff;
  cursor: text;
}

.css_1u763tv input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
}

.css_10xzrfw {
  padding: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  font-weight: normal;
}

.css_1xxao48 button {
  margin: 0 4px 0 12px;
}

.css_1gerhyj {
  display: flex;
  padding: 8px;
  border: 0;
  border-radius: 6px;
  background: none;
  cursor: pointer;
  margin: -8px -8px -8px 0;
}

.header_bottom {
  background-color: #f2f4f6;
  width: 100%;
  height: 1px;
  margin: 0;
}


/* media Query */

@media screen and (min-width: 320px) {
  nav {
    height: 55px;
  }
}

@media screen and (min-width: 768px) {
  nav {
    height: 96px;
  }

  header {
    position: sticky;
    top: 0;
  }

  .second_nav {
    display: none;
  }

  /* react에서도 hb1, hb2 매우 필요한 요소다. 구분선 역할을 한다. */
  .hb2 {
    display: none;
  }
}

/* max-width */

@media (max-width: 767px) {
  .logo {
    margin-top: 16px;
  }

  .right_GNB svg {
    margin-top: 16px;
  }

  .css_8t0ewa {
    padding: 32px 20px;
  }

  .css_192tn2r {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .css_b3uwyt {
    margin: 0;
  }

  .css_sx764e {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 12px 0 16px;
  }

  .css_sx764e>div {
    margin-top: 10px;
  }

  .css_sx764e a {
    margin-right: 18px;
  }

  .first_nav {
    padding: 0 16px;
  }

  .second_nav {
    padding: 0 20px;
  }

  /* react에서도 hb1, hb2 매우 필요한 요소다. 구분선 역할을 한다. */
  .hb1 {
    display: none;
  }
}