@charset "utf-8";

a {
  display: block;
}

/* 간편 회원가입 */
.sign_up {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
}

.sign_line {
  position: relative;
  bottom: -8px;
  display: block;
  margin: 0;
  width: 100%;
  height: 1px;
  background-color: #f1f3f5;
  border: none;
}

.sign_title {
  padding: 0 8px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -.3px;
  color: #abb0b5;
  z-index: 11;
  background-color: #fff;
}

.sign_button {
  display: flex;
  margin: auto;
  column-gap: 24px;
}

.social_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}

.social_button:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  .sign_title {
    font-size: 14px;
  }
}